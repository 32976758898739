@import url(../fonts/stylesheet.css);

html {
    scroll-behavior: auto;
    height: auto;
    font-size: 62.5%;
}

.lenis.lenis-smooth {
    scroll-behavior: auto;
}

:root {

    --bodyFont: 'Playfair Display', serif;
    --HeadingFont: 'SA Triumph';
    --HeadingOutlineFont: 'SA Triumph Outline';
    --primaryColor: #e01a4f;
    --primaryHoverColor: #6b5238;
    --black: #000000;
    --black__tint1: #111517;
    --black__tint2: #191919;
    --black__tint3: #212121;
    --white: #ffffff;
    --white__tint1: #949494;
    --white__tint2: #d4d4d4;
    --white__60: #a0a0a1;
    --dusty__grey: #666666;

    --blue: #0d00a4;
    --blue__tint1: #22007c;
    --blue__tint2: #140152;
    --blue__tint3: #04052e;


    --BodyFontSize: 1.4rem;
    --BodyLineHeight: 2.2rem;
    --heading1: 4rem;
    --heading2: 3.6rem;
    --heading3: 3rem;
    --heading4: 2.6rem;
    --heading5: 2.2rem;
    --heading6: 1.6rem;


    --fontsize: 62px
}


.red {
    background-color: red;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #b1b1b1 #f5f5f5;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*::-webkit-scrollbar {
    width: 9px;
}

*::-webkit-scrollbar-track {
    background: var(--black__tint1);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--white);
    border-radius: 20px;
    border: 1px solid #f5f5f5;
}



html,
body {
    height: auto;
}

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
}


body {
    background-color: var(--black__tint1);
    color: var(--white__tint1);
    font-family: var(--bodyFont), "Helvetica Neue", "Helvetica", Helvetica, Arial;
    font-size: var(--BodyFontSize);
    line-height: var(--BodyLineHeight);
    letter-spacing: 0.02em;
    text-align: left;
    text-rendering: optimizeLegibility;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-x: hidden;
}

::-moz-selection {
    color: var(--white);
    background-color: var(--blue__tint1);
}

::selection {
    color: var(--white);
    background-color: var(--blue__tint1);
}


h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 2rem;
    margin-top: 0;
    font-weight: 700;
    line-height: normal;
    font-family: var(--HeadingFont);
    color: var(--white);
}

h1,
.h1 {
    font-size: var(--heading1);
}

h2,
.h2 {
    font-size: var(--heading2);
}

h3,
.h3 {
    font-size: var(--heading3);
}

h4,
.h4 {
    font-size: var(--heading4);
}

h5,
.h5 {
    font-size: var(--heading5);
}

h6,
.h6 {
    font-size: var(--heading6);
}

p {
    font-size: var(--BodyFontSize);
    margin: 0;
    margin-bottom: 1.2rem;
}

a {
    color: var(--body-color);
    text-decoration: none;
    -webkit-transition: all ease-in-out 0.2s;
    -o-transition: all ease-in-out 0.2s;
    transition: all ease-in-out 0.2s;
}

button {
    -webkit-transition: all ease-in-out 0.2s;
    -o-transition: all ease-in-out 0.2s;
    transition: all ease-in-out 0.2s;
}


a:hover {
    color: var(--primaryHover);
}

img {
    max-width: 100%;
    height: auto;
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

pre {
    font-family: monospace, monospace;
    font-size: 1em;
}

a {
    background-color: transparent;
}

abbr[title] {
    border-bottom: 0;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
}

b,
strong {
    font-weight: bolder;
}

code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

img {
    border-style: none;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

fieldset {
    padding: 0.35em 0.75em 0.625em;
}

legend {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

textarea {
    overflow: auto;
}

[type="checkbox"],
[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}


select,
input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
}

a:hover,
a:focus,
button,
button:hover,
button:focus,
input:focus,
textarea:focus,
select:focus {
    text-decoration: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

input:-webkit-autofill {
    background-color: var(--white) !important;
    -webkit-box-shadow: 0 0 0 1000px var(--white) inset;
    -webkit-transition-property: var(--white) !important;
    /*     background-clip: content-box; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--black__tint2);
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    -webkit-transition: background-color 500000s ease-in-out 0s !important;
    transition: background-color 500000s ease-in-out 0s !important;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

details {
    display: block;
}

summary {
    display: list-item;
}

template {
    display: none;
}

[hidden] {
    display: none;
}


*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
}

button {
    cursor: pointer;
}

label {
    margin-bottom: 1rem;
}

code,
pre {
    font-family: "SFMono-Regular", "SF Mono", "Ubuntu Mono", "Consolas", "DejaVu Sans Mono", "Menlo", monospace;
}

.textCenter {
    text-align: center;
}

.textUpercase {
    text-transform: uppercase;
}

.commonBackground {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.container {
    width: 100%;
    max-width: calc(100% - 10vw);
    margin: 0 auto;
}

.menubartop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
}

.menubartop__logo {
    font-weight: 500;
    color: var(--white);
    font-family: var(--HeadingFont);
}

.toggleBtn__parent {
    width: 60px;
}

.toggleBtn {
    background: transparent;
    border: 0;
    width: 38px;
    height: 38px;
    background: var(--black);
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* opacity: 0; */
    /* pointer-events: none; */
}

.toggleBtn span {
    width: 30px;
    height: 2px;
    display: block;
    background: var(--white);
    -webkit-transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.toggleBtn span+span {
    margin-top: 5px;
}

.showmenu .toggleBtn span:nth-child(1) {
    -webkit-transform: rotate(45deg) translate(5px, 5px);
    -ms-transform: rotate(45deg) translate(5px, 5px);
    transform: rotate(45deg) translate(5px, 5px);
}

.showmenu .toggleBtn span:nth-child(2) {
    opacity: 0;
}

.showmenu .toggleBtn span:nth-child(3) {
    -webkit-transform: rotate(-45deg) translate(5px, -5px);
    -ms-transform: rotate(-45deg) translate(5px, -5px);
    transform: rotate(-45deg) translate(5px, -5px);
}


.showmenu body,
.showmenu {
    overflow: hidden;
}

.menubartop {
    padding: 1rem 0;
    mix-blend-mode: difference;
}

.mainBanner {
    padding: 8rem 0;
    min-height: 100vh;
}

/* .mainBanner__title {
    font-size: calc(var(--heading1) + 2.1rem); 
} */

.mainBanner__title {
    font-size: var(--heading1);
}

.autoScrollingText {
    font-size: calc(9rem + 5vw);
    font-size: clamp(8rem, 15vw, 16rem);
    line-height: 1;
    line-height: normal;
    font-weight: 600;
    text-transform: uppercase;    
    display: flex;
    flex-wrap: nowrap;
    width: 100vw;
    overflow: hidden;
}

.autoScrollingText__innr {
    display: flex;
    white-space: no-wrap;
}

.autoScrollingText span {
    font-weight: normal;
    font-size: inherit;
    display: block;
    white-space: nowrap;
    color: #fff;
    font-family: var(--HeadingOutlineFont);
}

.autoScrollingText .ticker-wrapper {
    display: flex;
    flex-wrap: nowrap;
}

.autoScrollingText .ticker-wrapper span {
    font-weight: normal;
    font-size: inherit;
    display: block;
    white-space: nowrap;
    color: #fff;
    font-family: var(--HeadingOutlineFont);
}


.commonSection {
    padding: 6rem 0;
}



.mainBanner__title span {
    display: block;
}


.mainsite {
    position: relative;
    z-index: 2;

}

.backobject {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
}


.backobject__canvas {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
}




.commonSection {
    padding: 6rem 0;
}

/* 
.experianceSection {
    min-height: 100vh;
} */

.webProjectlist li {
    list-style: none;
    margin: 0;
    border-bottom: 1px solid rgba(228, 228, 228, 0.3);
    font-weight: 700;
    transform: translateY(150);
    opacity: 0,
}

.webProjectlist li a {
    margin: 0;
    display: block;
    padding: 3rem 0;
    position: relative;
    -webkit-transition: 0.4s cubic-bezier(.16, -0.1, .11, 1.57);
    -o-transition: 0.4s cubic-bezier(.16, -0.1, .11, 1.57);
    transition: 0.4s cubic-bezier(.16, -0.1, .11, 1.57);
    overflow: hidden;
}

.webProjectlist li a span {
    width: 40px;
    height: 40px;
    background: var(--blue);
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
    -webkit-transform: translateY(70px);
    -ms-transform: translateY(70px);
    transform: translateY(70px);
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
}

.webProjectlist li a span img {
    width: 30px;
}

.webProjectlist li a:hover span {
    -webkit-transform: rotate(-45deg) translateY(0);
    -ms-transform: rotate(-45deg) translateY(0);
    transform: rotate(-45deg) translateY(0);
}

.footermain {
    /* background: var(--black__tint1); */
    position: relative;
    z-index: 1;
    padding: 6rem 0;
}

.loader {
    position: relative;
    z-index: 2;
}

.loader__inner {
    height: 100%;
}

.loader__bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: var(--black__tint2);
    -webkit-transition: 0.8s ease-in-out;
    -o-transition: 0.8s ease-in-out;
    transition: 0.8s ease-in-out;

}

.loader__bg.hide {
    -webkit-transform: translatey(-100vw);
    -ms-transform: translatey(-100vw);
    transform: translatey(-100vw);
}

.loader__wrapper {
    width: 100%;
    text-align: center;
}

.loaderProgress {
    background: var(--white);
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
}

.loaderProgress__inner {
    height: 2px;
    background: var(--black__tint1);
}

.loading__timer.h1 {
    font-size: clamp(3rem, 50vw, 16rem);
}

.mainBanner__cntnt p {
    width: 100%;
    max-width: 59.6rem;
    font-size: 2.6rem;
    line-height: 4.4rem;
    margin-bottom: 19px;
}

.seprateLine {
    width: 113px;
    height: 3px;
    background: var(--white);
    margin: 3.8rem 0;
}

.qualitiesSection {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.qualitiesRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 3rem;
    column-gap: 6rem;
}

.qualitiesBox__img img {
    display: block;
    width: 80px;
    opacity: 0.58;
}

.qualitiesBox__img {
    margin-bottom: 3rem;
}

.testimonialBox p {
    font-size: 1.8rem;
    margin-bottom: 4rem;
}

.testimonialButton__btn {
    background: transparent;
    border: 0;
    user-select: none;
    transition: 0.3s ease-in-out;
}

.testimonialButton__btn.slick-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.testimonialButton__btn img {
    width: 30px;
    transition: inherit;
}

.testimonialButton__btn--left img {
    transform: rotate(180deg);
}

.footermain__mandalaBox {
    position: relative;
    padding: 10rem 0;
}

.mandala {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    user-select: none;
}

.mandala img {
    -webkit-animation: spin 21s linear infinite;
    -moz-animation: spin 21s linear infinite;
    animation: spin 21s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.footermain__contact {
    position: relative;
    z-index: 2
}

.footermain__bottom .container {
    border-top: 1px solid var(--black__tint3);
}

.footermain__bottom {
    margin-top: 8rem;
}

.footermain__bottom .container {
    padding-top: 4rem;

}

.footermain__bottom .listing {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
}

.footermain__bottom .listing li a {
    font-size: 1.4rem;
    color: var(--white);
}

.footermain__bottom .listing li a:hover {
    color: var(--primar);
}

.footermain__contactBtn {
    margin-top: 6rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.left {
    color: var(--dusty__grey);
}

.projectImgHolder__img img {
    display: block;
    width: 100%;
}

.projectImgHolder__heading {
    padding-top: 4rem;
}

.projectSection .row {
    row-gap: 7rem;
}

.projectImgHolder__img {
    position: relative;
    overflow: hidden;
    height: 40vw;
    display: block;
}

.projectImgHolder__img img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(40vw + 10%);
    object-fit: cover;
    object-position: top;
    padding: 0;
}



.mainBanner__cntnt {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    opacity: 0;
}

/*  */

.menuBox {
    position: fixed;
    inset: 0 auto 0 0;
    z-index: 9;
    padding: 100px 40px;
    background: var(--black__tint3);
    background-image: url(../images/niose.png);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
    pointer-events: none;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;

}

.showmenu .menuBox {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
}

.menuBox__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.menuBox__left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 575px;
}

.menuBox__right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 519px;
}

.menuBox__ul {
    margin: 0;
    padding: 0;
}

.menuBox__ul li {
    list-style: none;
    display: block;
    margin: 1rem 0;
}




.menuBox__ul li a {
    padding: 11px 0 4px;
    font-size: var(--fontsize);
    line-height: 1;
    font-family: var(--HeadingFont);
    color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.menuBox__ul li .arrow {
    width: 38px;
    margin-left: 7px;
    display: block;
    margin-left: auto;
    overflow: hidden;
}

.menuBox__ul li .arrow img {
    width: 100%;
    display: block;
    -webkit-transform: translate(-100%, 100%);
    -ms-transform: translate(-100%, 100%);
    transform: translate(-100%, 100%);
    -webkit-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.menuBox__Links {
    height: var(--fontsize);
    overflow: hidden;
}

.menuBox__Links span {
    display: block;
    color: inherit;
    font-size: inherit;
    -webkit-transition: 0.4s ease-in-out;
    -o-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
}

.menuBox__Links span:nth-child(2) {
    color: var(--white);
}

.menuBox__ul li a:hover {
    border-color: var(--white);
}

.menuBox__ul li a:hover .arrow>img {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}

.menuBox__ul li a:hover .menuBox__Links>span {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

.qualitiesSection__left {
    max-width: 50%;
}

.testimonialSlider .slick-slide {
    margin: 0 15px;
}

.testimonialSlider .slick-list {
    margin: 0 -15px;
}

.commonButton {
    border: 0;
    padding: 12px 45px;
    border: 1px solid var(--white);
    background: transparent;
    color: #fff;
    display: inline-block;
    font-family: var(--bodyFont);
    border-radius: 0;
    font-size: 1.8rem;
    position: relative;
    overflow: hidden;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;


    &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 0;
        background: var(--white);
        /* transform: translateX(-100%); */
        transition: inherit;
        z-index: -1;
        transition-timing-function: 2 cubic-bezier(0.45, 1.64, 0.47, 0.66);

    }

}



.commonButton:hover {
    color: var(--black__tint1);

    &::before {
        left: 0;
         width: 100%;
    }
}

.projectSection__btn {
    margin-top: 20px;
}



.testimonialSlider .slick-track {
    display: flex;
}

.cursor {
    pointer-events: none;
    position: fixed;
    padding: 0.3rem;
    background-color: #fff;
    border-radius: 50%;
    mix-blend-mode: difference;
    transition: transform 0.3s ease;
    z-index: 9999999;
}

.hover:hover .cursor {
    transform: scale(8);
}

.hover {
    transition: all 0.3s ease;
}