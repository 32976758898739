@font-face {
    font-family: 'SA Triumph';
    src: url('SATriumphRegular.eot');
    src: url('SATriumphRegular.eot?#iefix') format('embedded-opentype'),
        url('SATriumphRegular.woff2') format('woff2'),
        url('SATriumphRegular.woff') format('woff'),
        url('SATriumphRegular.ttf') format('truetype'),
        url('SATriumphRegular.svg#SATriumphRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SA Triumph Outline';
    src: url('SATriumphOutline.eot');
    src: url('SATriumphOutline.eot?#iefix') format('embedded-opentype'),
        url('SATriumphOutline.woff2') format('woff2'),
        url('SATriumphOutline.woff') format('woff'),
        url('SATriumphOutline.ttf') format('truetype'),
        url('SATriumphOutline.svg#SATriumphOutline') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

