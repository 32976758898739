.container__small {
    width: calc(100% - 10vw);
    max-width: 140rem;
    margin: 0 auto;
}

.blogHolder {
    margin-bottom: 5rem;
    color: var(--white__tint2);
}

.blogHolder h5 {
    transition: 0.4s ease-in-out all;
}

.blogHolder:hover h5 {
    color: var(--primaryColor);
}

.blogHolder .blogHolder__img {
    position: relative;
    margin-bottom: 1.5rem;
    overflow: hidden;
}

.blogHolder .blogHolder__img img {
    display: block;
    width: 100%;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.blogHolder .blogHolder__cntnt h3 { 
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.blogHolder .blogHolder__cntnt .blogHolder__date { 
}

.blogArticalOuter {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
}

.blogArticalOuter__image {
    margin: 0 0 4rem;
}

.blogArticalOuter__content {
    width: 100%;
    max-width: 80%;
    margin: 0 auto 6rem;
}

.innerpagetitle {
    margin-bottom: 4.7rem;
    padding: 2rem 0 0;
}

.blogTitleDate {}

.blogArticalOuter__content p {
    margin: 0 0 3rem;
}

.blogArticalOuter__content li {
    margin: 0 0 5px;
}

.blogArticalOuter__content ul, .blogArticalOuter__content ol {
    margin: 0 0 3rem;
}

.blogArticalOuter__content p:last-of-type {
    margin-bottom: 0;
}

.blogArticalOuter__content a {
    /* color: var(--primaryBtn); */
}

.blogArticalOuter__content strong {
    font-weight: 700;
}

.blogArticalOuter__content :is(h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6) {
    margin-bottom: 1.2rem;
}

pre {
    background-color: #2e3440;
    /* Dark background */
    padding: 15px;
    border-radius: 8px;
    overflow-x: auto;
    font-size: 14px;
    line-height: 1.5;
}

/* Style for <code> tag to differentiate code from normal text */
code {
    color: #d8dee9;
    /* Light text */
    font-family: 'Fira Code', 'Courier New', monospace;
    /* Monospaced font */
}

/* Optional: Style inline <code> elements differently */
code.inline {
    background-color: #3b4252;
    padding: 2px 4px;
    border-radius: 4px;
}

/* Example syntax highlighting (optional, can be customized) */
pre code .keyword {
    color: #81a1c1;
}

pre code .string {
    color: #a3be8c;
}

pre code .comment {
    color: #616e88;
    font-style: italic;
}

pre code .function {
    color: #88c0d0;
}

pre code .number {
    color: #b48ead;
}

@media only screen and (max-width: 767px) {
    .blogArticalOuter {
        max-width: 100%;
    }

    .blogArticalOuter__content {
        width: 100%;
        max-width: 100%;
        margin: 0 auto 3rem;
    }
}



@media only screen and (min-width: 576px) {
    ul.doubleList {
        column-count: 2;
    }
}