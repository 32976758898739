@media only screen and (min-width:1200px) {
    :root {

        --heading1: 5.1rem;
        --heading2: 4.2rem;
        --heading3: 3.6rem;
        --heading4: 3rem;
        --heading5: 2.6rem;
        --heading6: 2rem;
        --BodyFontSize: 2rem;
        --BodyLineHeight: 1.55;
    }

    .mainBanner__title {
        font-size: calc(var(--heading1) + 2.1rem);
    }
}

@media only screen and (min-width:992px) {}

@media only screen and (max-width:1400px) {}


/* height: 767px */
@media only screen and (max-width:1300px) {}

@media only screen and (max-width:1199px) {
    .qualitiesSection__left {
        max-width: 80%;
    }

    .autoScrollingText {
        font-size: calc(9rem + 5vw);
        font-size: clamp(5rem, 7vw, 7rem);
    }

    .mainBanner__cntnt p {
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

}

/* 1199 */
@media only screen and (max-width:991px) {
    :root {

        --heading1: 3.6rem;
        --heading2: 3.2rem;
        --heading3: 2.8rem;
        --heading4: 2.4rem;
        --heading5: 2.1rem;
        --heading6: 1.5rem;
    }

    .qualitiesSection__left {
        max-width: 100%;
    }

    .autoScrollingText {
        font-size: calc(2rem + 5vw);
        font-size: clamp(2rem, 5vw, 8rem);
    }

    .mainBanner__cntnt p {
        width: 356px;
        font-size: 1.8rem;
        line-height: 2.4rem;
    }
}

@media only screen and (max-width:767px) {
    :root {

        --heading1: 3.4rem;
        --heading2: 3rem;
        --heading3: 2.6rem;
        --heading4: 2.2rem;
        --heading5: 2rem;
        --heading6: 1.4rem;

        --fontsize: 45px;
    }

    .commonSection {
        padding: 4rem 0;
    }

    .footermain__bottomRow {
        flex-direction: column-reverse;
        gap: 10px;
    }

    .footermain__bottom .listing {
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
    }

    .footermain__contactBtn {
        margin-top: 3rem;
    }

    .projectImgHolder__img {
        height: 400px;
    }

    .projectImgHolder__img img {
        height: calc(400px + 10%);
    }

    .loading__timer.h1 {
        font-size: clamp(3rem, 45vw, 12rem);
    }
}

/* 767 */
@media only screen and (max-width:575px) {

    :root {
        --fontsize: 36px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        margin-bottom: 1rem;
    }

    .container {
        max-width: calc(100% - 30px);
    }

    .loading__timer.h1 {
        font-size: clamp(3rem, 40vw, 7rem);
    }

    .mainBanner__cntnt p {
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

    .qualitiesRow {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 2rem;
        column-gap: 0;
    }

    .qualitiesSection__left {
        max-width: 100%;
    }

    .qualitiesBox__img img {
        width: 60px;
    }

    .qualitiesBox__img {
        margin-bottom: 1rem;
    }

    .mainBanner {
        padding: 8rem 0 0;
    }

    .webProjectlist li a {
        padding: 2rem 0;
    }

    .projectImgHolder__img {
        height: 300px;
    }

    .projectImgHolder__img img {
        height: calc(300px + 10%);
    }

    .footermain__contactBtn {
        margin-top: 2rem;
    }

    .testimonialSlider .slick-list, .testimonialSlider .slick-slide {
        margin: 0px;
    }
}

/* 575 */

@media only screen and (max-width:480px) {}

/* 480 */